import Icons from '@gameonsports/components/cjs/Icons'
import Notification from '@gameonsports/components/cjs/Notification'
import theme from '@gameonsports/components/cjs/theme'
import loadable from '@loadable/component'
import {
  Location,
  Match,
  Redirect,
  RouteComponentProps,
  Router,
  navigate,
} from '@reach/router'
import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSSR } from 'react-i18next'
import styled, { createGlobalStyle, css } from 'styled-components'
import AuthenticationBoundary from '../../components/AuthenticationBoundary'
import GlobalFooter from '../../components/GlobalFooter'
import GlobalHeader from '../../components/GlobalHeader'
import PageLoader from '../../components/PageLoader'
import PageLoadErrorBoundary from '../../components/PageLoadErrorBoundary'
import { REGISTRATION_PAYMENT_CALLBACK } from '../../constants/registrationPaths'
import { INITIAL_I18N_STORE_SCRIPT_ID } from '../../constants/scriptIds'
import { IS_WEBVIEW_QUERY_KEY } from '../../constants/webview'
import AccountProvider from '../../contexts/AccountProvider'
import ThemeContext from '../../contexts/ThemeContext'
import UnconfirmedEmailContext from '../../contexts/UnconfirmedEmailContext'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import { useQueryString } from '../../hooks/useQueryString'
import { getExternalIDRegCodeStoragePath } from '../../utils/registration'
import { isSSR } from '../../utils/ssr'
import { media } from '../../utils/styled-components-utils'

const NotFound = loadable(
  () => import('../404' /* webpackChunkName: "404" */),
  { fallback: <PageLoader /> },
)

const Home = loadable(() => import('../Home' /* webpackChunkName: "Home" */), {
  fallback: <PageLoader />,
})

const AppLink = loadable(
  () => import('../AppLink' /* webpackChunkName: "AppLink" */),
  {
    fallback: <PageLoader />,
  },
)

const SportTenants = loadable(
  () => import('../SportTenants' /* webpackChunkName: "SportTenants" */),
  {
    fallback: <PageLoader />,
  },
)

const About = loadable(
  () => import('../About' /* webpackChunkName: "About" */),
  { fallback: <PageLoader /> },
)

const Account = loadable(
  () => import('../Account' /* webpackChunkName: "Account" */),
  { fallback: <PageLoader /> },
)

const Maintenance = loadable(
  () => import('../Maintenance' /* webpackChunkName: "Maintenance" */),
  { fallback: <PageLoader /> },
)

const MyTeams = loadable(
  () => import('../MyTeams' /* webpackChunkName: "MyTeams" */),
  { fallback: <PageLoader /> },
)

const MyTeamsFixture = loadable(
  () => import('../MyTeamsFixture' /* webpackChunkName: "MyTeamsFixture" */),
  { fallback: <PageLoader /> },
)

const MyTeamsLadder = loadable(
  () => import('../MyTeamsLadder' /* webpackChunkName: "MyTeamsLadder" */),
  { fallback: <PageLoader /> },
)

const MyTeamsSquad = loadable(
  () => import('../MyTeamsSquad' /* webpackChunkName: "MyTeamsSquad" */),
  { fallback: <PageLoader /> },
)

const MyTeamsTeam = loadable(
  () => import('../MyTeamsTeam' /* webpackChunkName: "MyTeamsTeam" */),
  { fallback: <PageLoader /> },
)

const ExternalProviderLogin = loadable(
  () =>
    import(
      '../ExternalProviderLogin' /* webpackChunkName: "ExternalProviderLogin" */
    ),
  { fallback: <PageLoader /> },
)

const Login = loadable(
  () => import('../Login' /* webpackChunkName: "Login" */),
  { fallback: <PageLoader /> },
)

const Logout = loadable(
  () => import('../Logout' /* webpackChunkName: "Logout" */),
  { fallback: <PageLoader /> },
)

const Signup = loadable(
  () => import('../Signup' /* webpackChunkName: "Signup" */),
  { fallback: <PageLoader /> },
)

const SignupConfirm = loadable(
  () => import('../SignupConfirm' /* webpackChunkName: "SignupConfirm" */),
  { fallback: <PageLoader /> },
)

const SignupResend = loadable(
  () => import('../SignupResend' /* webpackChunkName: "SignupResend" */),
  { fallback: <PageLoader /> },
)

const ForgotPassword = loadable(
  () => import('../ForgotPassword' /* webpackChunkName: "ForgotPassword" */),
  { fallback: <PageLoader /> },
)

const ResetPassword = loadable(
  () => import('../ResetPassword' /* webpackChunkName: "ResetPassword" */),
  { fallback: <PageLoader /> },
)

const ChangeEmail = loadable(
  () => import('../ChangeEmail' /* webpackChunkName: "ChangeEmail" */),
  { fallback: <PageLoader /> },
)

const ChangeEmailVerify = loadable(
  () =>
    import('../ChangeEmailVerify' /* webpackChunkName: "ChangeEmailVerify" */),
  { fallback: <PageLoader /> },
)

const FindMyProfiles = loadable(
  () => import('../FindMyProfiles' /* webpackChunkName: "FindMyProfiles" */),
  { fallback: <PageLoader /> },
)

const Registration = loadable(
  () => import('../Registration' /* webpackChunkName: "Registration" */),
  { fallback: <PageLoader /> },
)

const RegistrationOverview = loadable(
  () =>
    import(
      '../RegistrationOverview' /* webpackChunkName: "RegistrationOverview" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationChooseWho = loadable(
  () =>
    import(
      '../RegistrationChooseWho' /* webpackChunkName: "RegistrationChooseWho" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationRequestToPlay = loadable(
  () =>
    import(
      '../RegistrationRequestToPlay' /* webpackChunkName: "RegistrationRequestToPlay" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationRole = loadable(
  () =>
    import('../RegistrationRole' /* webpackChunkName: "RegistrationRole" */),
  { fallback: <PageLoader /> },
)

const RegistrationParticipantDetails = loadable(
  () =>
    import(
      '../RegistrationParticipantDetails' /* webpackChunkName: "RegistrationParticipantDetails" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationClaimProfiles = loadable(
  () =>
    import(
      '../RegistrationClaimProfiles' /* webpackChunkName: "RegistrationClaimProfiles" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationFees = loadable(
  () =>
    import('../RegistrationFees' /* webpackChunkName: "RegistrationFees" */),
  { fallback: <PageLoader /> },
)

const RegistrationProducts = loadable(
  () =>
    import(
      '../RegistrationProducts' /* webpackChunkName: "RegistrationProducts" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationExternalAccount = loadable(
  () =>
    import(
      '../RegistrationExternalAccount' /* webpackChunkName: "RegistrationExternalAccount" */
    ),

  { fallback: <PageLoader /> },
)

const RegistrationExternalID = loadable(
  () =>
    import(
      '../RegistrationExternalID/RegistrationExternalID' /* webpackChunkName: "RegistrationExternalID" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationPayment = loadable(
  () =>
    import(
      '../RegistrationPayment' /* webpackChunkName: "RegistrationPayment" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationPaymentCallback = loadable(
  () =>
    import(
      '../RegistrationPaymentCallback' /* webpackChunkName: "RegistrationPaymentCallback" */
    ),
  { fallback: <PageLoader /> },
)

const RegisterSuccess = loadable(
  () =>
    import(
      '../RegistrationSuccess' /* webpackChunkName: "RegistrationSuccess" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationSocialTeam = loadable(
  () =>
    import(
      '../RegistrationSocialTeam' /* webpackChunkName: "RegistrationSocialTeam" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationTeamSuccess = loadable(
  () =>
    import(
      '../RegistrationTeamSuccess' /* webpackChunkName: "RegistrationTeamSuccess" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationTransferRequest = loadable(
  () =>
    import(
      '../RegistrationTransferRequest' /* webpackChunkName: "RegistrationTransferRequest" */
    ),
  { fallback: <PageLoader /> },
)

const RegistrationTransferSuccess = loadable(
  () =>
    import(
      '../RegistrationTransferSuccess' /* webpackChunkName: "RegistrationTransferSuccess" */
    ),
  { fallback: <PageLoader /> },
)

const MyTeamsEnterVotes = loadable(
  () =>
    import('../MyTeamsEnterVotes' /* webpackChunkName: "MyTeamsEnterVotes" */),
  { fallback: <PageLoader /> },
)

const MyTeamsGameDetails = loadable(
  () =>
    import(
      '../MyTeamsGameDetails' /* webpackChunkName: "MyTeamsGameDetails" */
    ),
  { fallback: <PageLoader /> },
)

const SeasonList = loadable(
  () => import('../SeasonList' /* webpackChunkName: "SeasonList" */),
  { fallback: <PageLoader /> },
)

const GradeList = loadable(
  () => import('../GradeList' /* webpackChunkName: "GradeList" */),
  { fallback: <PageLoader /> },
)

const Grade = loadable(
  () => import('../Grade' /* webpackChunkName: "Grade" */),
  { fallback: <PageLoader /> },
)

const Rounds = loadable(
  () => import('../Rounds' /* webpackChunkName: "Rounds" */),
  { fallback: <PageLoader /> },
)

const GradeLadder = loadable(
  () => import('../GradeLadder' /* webpackChunkName: "GradeLadder" */),
  { fallback: <PageLoader /> },
)

const GradeStatistics = loadable(
  () => import('../GradeStatistics' /* webpackChunkName: "GradeStatistics" */),
  { fallback: <PageLoader /> },
)

const GameCentre = loadable(
  () => import('../GameCentre' /* webpackChunkName: "GameCentre" */),
  { fallback: <PageLoader /> },
)

const Team = loadable(() => import('../Team' /* webpackChunkName: "Team" */), {
  fallback: <PageLoader />,
})

const TeamLadder = loadable(
  () => import('../TeamLadder' /* webpackChunkName: "TeamLadder" */),
  { fallback: <PageLoader /> },
)

const TeamFixture = loadable(
  () => import('../TeamFixture' /* webpackChunkName: "TeamFixture" */),
  { fallback: <PageLoader /> },
)

const TeamList = loadable(
  () => import('../TeamList' /* webpackChunkName: "TeamList" */),
  { fallback: <PageLoader /> },
)

const DiscoverRegistrations = loadable(
  () =>
    import(
      '../DiscoverRegistrations' /* webpackChunkName: "DiscoverRegistrations" */
    ),
  { fallback: <PageLoader /> },
)

const PrivacyPolicy = loadable(
  () => import('../PrivacyPolicy' /* webpackChunkName: "PrivacyPolicy" */),
  { fallback: <PageLoader /> },
)

const TermsAndConditions = loadable(
  () =>
    import(
      '../TermsAndConditions' /* webpackChunkName: "TermsAndConditions" */
    ),

  { fallback: <PageLoader /> },
)

const AFL = loadable(() => import('../AFL' /* webpackChunkName: "AFL" */), {
  fallback: <PageLoader />,
})

const BasketballVictoria = loadable(
  () =>
    import(
      '../BasketballVictoria' /* webpackChunkName: "BasketballVictoria" */
    ),

  { fallback: <PageLoader /> },
)

const SearchResults = loadable(
  () => import('../SearchResults' /* webpackChunkName: "SearchResults" */),
  { fallback: <PageLoader /> },
)

const Shop = loadable(() => import('../Shop' /* webpackChunkName: "Shop" */), {
  fallback: <PageLoader />,
})

const ShopCart = loadable(
  () => import('../ShopCart' /* webpackChunkName: "ShopCart" */),
  {
    fallback: <PageLoader />,
  },
)

const ShopProduct = loadable(
  () => import('../ShopProduct' /* webpackChunkName: "ShopProduct" */),
  {
    fallback: <PageLoader />,
  },
)

const ShopCheckout = loadable(
  () => import('../ShopCheckout' /* webpackChunkName: "ShopCheckout" */),
  {
    fallback: <PageLoader />,
  },
)

const ShopCheckoutSuccess = loadable(
  () =>
    import(
      '../ShopCheckoutSuccess' /* webpackChunkName: "ShopCheckoutSuccess" */
    ),
  {
    fallback: <PageLoader />,
  },
)

const Tenant = loadable(
  () => import('../Tenant' /* webpackChunkName: "Tenant" */),
  { fallback: <PageLoader /> },
)

const TenantLanding = loadable(
  () => import('../TenantLanding' /* webpackChunkName: "TenantLanding" */),
  { fallback: <PageLoader /> },
)

const PublicProfile = loadable(
  () => import('../PublicProfile' /* webpackChunkName: "PublicProfile" */),
  { fallback: <PageLoader /> },
)

const NotificationsUnsubscribe = loadable(
  () =>
    import(
      '../NotificationsUnsubscribe' /* webpackChunkName: "NotificationsUnsubscribe" */
    ),
  { fallback: <PageLoader /> },
)

const PaymentRequest = loadable(
  () => import('../PaymentRequest' /* webpackChunkName: "PaymentRequest" */),
  { fallback: <PageLoader /> },
)

const PaymentRequestCheckoutSuccess = loadable(
  () =>
    import(
      '../PaymentRequestCheckoutSuccess' /* webpackChunkName: "PaymentRequestCheckoutSuccess" */
    ),
  { fallback: <PageLoader /> },
)

interface LayoutWrapperProps {
  background: keyof typeof theme | null
  hideSwishBackground: boolean | null
  $isWebview: boolean
}

const LayoutWrapper = styled.section<LayoutWrapperProps>`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  z-index: 1;

  background-color: ${props =>
    props.background
      ? props.theme[props.background]
      : props.theme.bodyBackground};

  > main {
    display: flex;
    flex: 1 1 auto;

    > div {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }
  }

  ${props =>
    !props.hideSwishBackground &&
    css`
      /*
        gr4vy does not work with styled components. If we wrap one of the
        secure fields with styled components then the app just crashes. I attempted to 
        put this at a lower level but it didn't apply. 
        https://docs.gr4vy.com/guides/payments/secure-fields/theming
       */
      .secure-fields__input {
        height: 0.75rem;
        padding: 0;
      }
      &::before {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        background-color: ${props => props.theme.blackberry400};
        height: 30rem;
        clip-path: ellipse(250% 100% at 50% 0%);

        ${props.$isWebview &&
        css`
          top: -4.375rem;
        `}

        ${media.tablet`
          height: 27rem;
          clip-path: ellipse(140% 100% at 50% 0%);
        `}

        ${media.desktop`
          height: 30rem;
          clip-path: ellipse(100% 100% at 50% 0%);
        `}

        ${media.largeDesktop`
          clip-path: ellipse(80% 100% at 50% 0%);
        `}
      }
    `}
`

interface GlobalProps<T> {
  theme?: T
  background?: keyof T
  overflowHidden?: boolean
}

const Global = createGlobalStyle<GlobalProps<typeof theme>>`
  @font-face {
    font-family: Athletics-Black;
    src: url(/static/fonts/Athletics-Black.woff) format('woff'),
         url(/static/fonts/Athletics-Black.woff2) format('woff2');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
  }
  @font-face {
      font-family: Athletics-Bold;
      src: url(/static/fonts/Athletics-Bold.woff) format('woff'),
          url(/static/fonts/Athletics-Bold.woff2) format('woff2');
      font-style: normal;
      font-weight: 400;
      text-rendering: optimizeLegibility;
  }

  html {
    box-sizing: border-box;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fontFamily};
    font-size: 1rem;
    font-display: swap;
    line-height: 1.375;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${props => props.theme.bodyCopy};
    ${props =>
      props.overflowHidden &&
      css`
        overflow: hidden;
      `}

    *, *::before, *::after {
      box-sizing: border-box;

      &:focus {
        outline-color: ${props => props.theme.primary};
      }
    }

    fieldset {
      border: 0;
      margin: 0;
      padding: 0;
      min-inline-size: auto;
    }

    noscript {
      color: ${props => props.theme.white400};
      position: relative;
      z-index: 1;
      background: ${props => props.theme.primary};
      display: block;
      text-align: center;
      padding: 0.5rem;
    }
  }

  h1 {
    font-size: 1.75rem;

    ${media.tablet`
      font-size: 2.25rem;
    `}
  }

  h2 {
    font-size: 1.25rem;

    ${media.tablet`
      font-size: 1.875rem;
    `}
  }

  h3 {
    font-size: 1.125rem;

    ${media.tablet`
      font-size: 1.25rem;
    `}
  }

  h4 {
    font-size: 1rem;

    ${media.tablet`
      font-size: 1.125rem;
    `}
  }

  h5 {
    font-size: 0.875rem;

    ${media.tablet`
      font-size: 1rem;
    `}
  }

  h6 {
    font-size: 0.75rem;

    ${media.tablet`
      font-size: 0.875rem;
    `}
  }
  
  .react-tooltip {
      z-index: 9;
  }
`
const CenteredNotification = styled(Notification)`
  justify-content: center;
`

const RegistrationExternalCallback: React.FC<
  RouteComponentProps<{ tenant?: string }>
> = ({ tenant }) => {
  if (typeof localStorage === 'undefined') return null
  const path = getExternalIDRegCodeStoragePath(tenant)
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
  const registrationStep = localStorage.getItem(path) ?? '/'

  return <Redirect to={`${registrationStep}/external-account-linking`} />
}

const RegistrationPaymentCallbackHelper: React.FC<RouteComponentProps> = () => {
  if (typeof localStorage === 'undefined') return null
  const registrationStep =
    // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
    localStorage.getItem(REGISTRATION_PAYMENT_CALLBACK) ?? '/'

  return <Redirect to={`${registrationStep}/payment-callback`} />
}

const RegistrationDefault: React.FC<{
  registrationCode?: string
  tenant?: string
  default: boolean
}> = ({ registrationCode, tenant }) => (
  <Redirect to={`/${tenant}/register/${String(registrationCode)}`} />
)

const AuthenticatedRegistrationRoutes: React.FC<
  RouteComponentProps<{
    registrationCode: string
    tenant: string
  }>
> = React.memo(({ registrationCode, tenant }) => (
  <AuthenticationBoundary
    onUnauthenticated={() =>
      navigate(`/${tenant}/register/${registrationCode}`)
    }
  >
    <Router primary={false}>
      <RegistrationChooseWho path="participant/1" />
      <RegistrationRole path="participant/2" />
      <RegistrationParticipantDetails path="participant/3" />
      <RegistrationClaimProfiles path="participant/:participantId/claim-profiles" />
      <RegistrationRequestToPlay path="participant/:participantId/request-to-play" />
      <RegistrationFees path="participant/:participantId/fees" />
      <RegistrationProducts path="participant/:participantId/products" />
      <RegistrationExternalID path="participant/:participantId/external-id" />
      <RegistrationPayment path="participant/:participantId/payment" />
      <RegistrationPaymentCallback path="participant/:participantId/payment-callback" />
      <RegisterSuccess path="participant/:participantId/success" />
      <RegistrationSocialTeam path="team/1" />
      <RegistrationFees path="team/:teamId/fees" />
      <RegistrationProducts path="team/:teamId/products" />
      <RegistrationPayment path="team/:teamId/payment" />
      <RegistrationPaymentCallback path="team/:teamId/payment-callback" />
      <RegistrationTeamSuccess path="team/:teamId/success" />
      <RegistrationTransferRequest path="transfer/:participantId" />
      <RegistrationTransferSuccess path="transfer/success" />
      <RegistrationDefault
        tenant={tenant}
        registrationCode={registrationCode}
        default
      />
    </Router>
  </AuthenticationBoundary>
))

const isInternetExplorer =
  !isSSR &&
  (window.navigator.userAgent.includes('MSIE') ||
    window.navigator.userAgent.includes('Trident'))

const initialI18nStore = !isSSR
  ? JSON.parse(
      document.getElementById(INITIAL_I18N_STORE_SCRIPT_ID)?.innerHTML ?? '{}',
    )
  : {}

const App = () => {
  const [email, setEmail] = useState<string | null>(null)
  const [background, setBackground] = useState<keyof typeof theme | null>(null)
  const [hideSwishBackground, setHideSwishBackground] = useState<
    boolean | null
  >(null)
  const { queryParams } = useQueryString()
  // Only use first instance of query param as navigating would remove it from the url
  const isWebview = useRef(
    queryParams[IS_WEBVIEW_QUERY_KEY]?.toString().toLowerCase() === 'true',
  ).current
  useSSR(initialI18nStore, 'en')
  const isLayupKillLoginOn = useFeatureFlagOn('layup-kill-login')
  const isPaymentRequestsOn = useFeatureFlagOn('layup-payment-requests')
  const isSIPCOn = useFeatureFlagOn('layup-sipc')

  return (
    <>
      {isInternetExplorer && (
        <CenteredNotification variant="warning">
          Internet Explorer is no longer supported. Please use another browser.
        </CenteredNotification>
      )}
      <AccountProvider>
        <UnconfirmedEmailContext.Provider
          value={{
            email,
            setEmail: (updatedEmail: string) => setEmail(updatedEmail),
          }}
        >
          <ThemeContext.Provider
            value={{
              background,
              setBackground,
              hideSwishBackground,
              setHideSwishBackground,
              isWebview,
            }}
          >
            <LayoutWrapper
              background={background}
              hideSwishBackground={hideSwishBackground}
              $isWebview={isWebview}
            >
              <Helmet titleTemplate="%s | PlayHQ" defaultTitle="PlayHQ" />
              <Global />
              <Location>
                {({ location }) => {
                  const hideSearch =
                    /^\/(?!(terms|privacy|about|search))([\w:-]+)?$/.test(
                      location.pathname,
                    )
                  return (
                    <GlobalHeader location={location} hideSearch={hideSearch} />
                  )
                }}
              </Location>
              <Match<{ '*': string }> path="/org/*">
                {({ match }) =>
                  match ? (
                    <Redirect to={`/basketball-victoria/org/${match['*']}`} />
                  ) : null
                }
              </Match>
              <Match<{ '*': string }> path="/register/*">
                {({ match }) =>
                  match ? (
                    <Redirect
                      to={`/basketball-victoria/register/${match['*']}`}
                    />
                  ) : null
                }
              </Match>
              <main>
                <PageLoadErrorBoundary>
                  <Router primary={false}>
                    {/* Used to fix render errors */}
                    <Redirect from="/index/*" to="/" />
                    <Redirect from="/index.html" to="/" />
                    <Home path="/" />
                    <AppLink path="/app-link" />
                    {/* Sport homepages */}
                    <SportTenants path="/australian-rules-football" />
                    <SportTenants path="/basketball" />
                    <SportTenants path="/netball" />
                    <SportTenants path="/cricket" />
                    <SportTenants path="/hockey" />
                    {/* End sport homepages */}
                    <About path="/about" />
                    <AuthenticationBoundary
                      path="/account"
                      onUnauthenticated={() => navigate(`/login`)}
                    >
                      <Account path="/*" />
                      <MyTeams path="/my-teams/:tenant/:teamName/:teamId">
                        <MyTeamsTeam path="/">
                          <NotFound default />
                          <MyTeamsFixture path="/" />
                          <MyTeamsLadder path="/ladder" />
                          <MyTeamsSquad path="/squad" />
                        </MyTeamsTeam>
                        <MyTeamsGameDetails path="/game/:gameId" />
                        <MyTeamsEnterVotes path="/game/:gameId/enter-votes" />
                      </MyTeams>
                    </AuthenticationBoundary>
                    <ChangeEmail path="/account/profile/change-email" />
                    <ChangeEmailVerify path="/account/profile/change-email/verify" />
                    {isSIPCOn && (
                      <FindMyProfiles path="/account/profile/find-my-profiles" />
                    )}
                    {/* Redirect old profile pages to account pages */}
                    <Redirect from="/profile" to="/account/profile" />
                    <Redirect
                      from="/profile/accreditations"
                      to="/account/accreditations"
                    />
                    <Redirect from="/profile/history" to="/account/history" />
                    <Redirect
                      from="/profile/memberships"
                      to="/account/memberships"
                    />
                    {/* End Redirect */}
                    {isLayupKillLoginOn ? (
                      <>
                        <Maintenance path="/login" />
                        <Maintenance path="/login/external-provider/:provider" />
                        <Maintenance path="/signup" />
                        <Maintenance path="/signup/verify" />
                        <Maintenance path="/forgot-password" />
                        <Maintenance path="/signup/resend" />
                        <Maintenance path="/reset-password/:token" />
                      </>
                    ) : (
                      <>
                        <Login path="/login" />
                        <ExternalProviderLogin path="/login/external-provider/:provider" />
                        <Signup path="/signup" />
                        <SignupConfirm path="/signup/verify" />
                        <ForgotPassword path="/forgot-password" />
                        <SignupResend path="/signup/resend" />
                        <ResetPassword path="/reset-password/:token" />
                      </>
                    )}
                    <Logout path="/logout" />
                    <Tenant path="/:tenant">
                      <NotFound default />
                      <TenantLanding path="/" />
                      <SeasonList path="/org/:organisationName/:organisationId" />
                      <GradeList path="/org/:organisationName/:competitionSeasonName/:seasonId" />
                      <Grade path="/org/:organisationName/:competitionSeasonName/:gradeName/:gradeId">
                        <Rounds path="/" />
                        <Rounds path="/:roundName" />
                        <GradeLadder path="/ladder" />
                        <GradeStatistics path="/statistics" />
                      </Grade>
                      <GameCentre path="/org/:organisationName/:competitionSeasonName/:gradeName/game-centre/:gameId" />
                      <TeamList path="/org/:organisationName/:organisationId/:competitionSeasonName/:seasonId/teams" />
                      <Team path="/org/:organisationName/:organisationId/:competitionSeasonName/teams/:teamName/:teamId">
                        <TeamFixture path="/" />
                        <TeamLadder path="/ladder" />
                      </Team>
                      <Team path="/org/:organisationName/:competitionSeasonName/teams/:teamName/:teamId">
                        <TeamFixture path="/" />
                        <TeamLadder path="/ladder" />
                      </Team>
                      <NotificationsUnsubscribe path="/notifications/unsubscribe" />
                      <DiscoverRegistrations path="/org/:organisationName/:organisationId/register" />
                      <Registration path="/register/:registrationCode">
                        <RegistrationOverview path="/" />
                        <Login path="/login" />
                        <AuthenticatedRegistrationRoutes path="*" />
                        <RegistrationExternalAccount path="/external-account-linking" />
                      </Registration>
                      <RegistrationExternalCallback path="/register/external-account-callback" />
                      <RegistrationPaymentCallbackHelper path="/register/payment-callback" />

                      <Shop path="/org/:organisationName/:orgCode/shop" />
                      <ShopCart path="/org/:organisationName/:orgCode/shop/cart" />
                      <ShopProduct path="/org/:organisationName/:orgCode/shop/:productId" />
                      <ShopCheckout path="/org/:organisationName/:orgCode/shop/checkout" />
                      <ShopCheckout
                        path="/org/:organisationName/:orgCode/shop/checkout-callback"
                        isCallback
                      />
                      <ShopCheckoutSuccess path="/org/:organisationName/:orgCode/shop/success" />

                      {isPaymentRequestsOn && (
                        <>
                          <PaymentRequest path="/org/:organisationName/:orgCode/payment-request/:productId" />
                          <PaymentRequest
                            path="/org/:organisationName/:orgCode/payment-request/:productId/callback"
                            isCallback
                          />
                          <PaymentRequestCheckoutSuccess path="/org/:organisationName/:orgCode/payment-request/success" />
                        </>
                      )}
                    </Tenant>

                    <PrivacyPolicy path="/privacy" />
                    <TermsAndConditions path="/terms" />
                    <AFL path="/about/afl" />
                    <BasketballVictoria path="/about/basketball-victoria" />
                    <SearchResults path="/search" />
                    <PublicProfile path="/public/profile/:profileId/*" />
                    <Redirect
                      from="/public/profile/:profileId"
                      to="/public/profile/:profileId/statistics"
                    />
                    <NotFound default />
                  </Router>
                </PageLoadErrorBoundary>
              </main>
              <GlobalFooter />
            </LayoutWrapper>
          </ThemeContext.Provider>
        </UnconfirmedEmailContext.Provider>
      </AccountProvider>

      {/* Icons at bottom of page */}
      <Icons />
    </>
  )
}

export default App
